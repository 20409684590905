import './sentry-setup';

// ** React
import React from 'react';
import ReactDOM from 'react-dom/client';

// ** Web Vitals
import reportWebVitals from './reportWebVitals';

// ** App
import App from './App';

// ** Contexts
import { AuthProvider } from 'src/@core/context/AuthContext';

// ** CSS styles
import './styles/globals.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
