// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Ticket Channels
export const getTicketChannels = createAsyncThunk('ticketChannels/getTicketChannels', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getTicketChannelsEndpoint, {
      params: params,
    });
    const data = response.data;

    return {
      ticketChannels: data.results || [],
      count: data.count || 0,
      filters: params || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Create Ticket Channel
export const createTicketChannel = createAsyncThunk('ticketChannels/createTicketChannel', async (params, { rejectWithValue }) => {
  try {
    const response = await http.post(apiConfig.createTicketChannelEndpoint, params);
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Ticket Channel
export const editTicketChannel = createAsyncThunk('ticketChannels/editTicketChannel', async (params, { rejectWithValue }) => {
  const { id, ...other } = params;

  try {
    const response = await http.patch(apiConfig.editTicketChannelEndpoint.replace(':id', id), other);
    const data = response.data;

    return {
      ticketChannel: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Delete Ticket Channel
export const deleteTicketChannel = createAsyncThunk(
  'ticketChannels/deleteTicketChannel',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.delete(apiConfig.deleteTicketChannelEndpoint.replace(':id', params.id));
      const data = response.data;

      dispatch(
        getTicketChannels({
          contract: params.contract,
        }),
      );

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Ticket Channel
export const getTicketChannel = createAsyncThunk('ticketChannels/getTicketChannel', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getTicketChannelEndpoint.replace(':id', params.id));
    const data = response.data;

    return {
      ticketChannel: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Bot Info
export const getBotInfo = createAsyncThunk('ticketChannels/getBotInfo', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getBotInfoEndpoint);
    const data = response.data;

    return {
      botInfo: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const ticketChannelsSlice = createSlice({
  name: 'ticketChannels',
  initialState: {
    isLoadingTicketChannels: false,
    isLoadingTicketChannel: false,
    ticketChannels: [],
    ticketChannel: {},
    ticketChannelsCount: 0,
    ticketChannelsError: false,
    ticketChannelError: false,
    ticketChannelsFilters: {
      page: 1,
      page_size: 25,
    },
  },
  reducers: {
    resetTicketChannel: (state) => {
      state.isLoadingTicketChannel = false;
      state.ticketChannelError = false;
      state.ticketChannel = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketChannels.pending, (state) => {
        state.isLoadingTicketChannels = true;
        state.ticketChannelsError = false;
      })
      .addCase(getTicketChannels.fulfilled, (state, action) => {
        state.ticketChannels = action.payload.ticketChannels;
        state.ticketChannelsCount = action.payload.count;
        state.ticketChannelsFilters = action.payload.filters;

        state.isLoadingTicketChannels = false;
      })
      .addCase(getTicketChannels.rejected, (state, action) => {
        state.ticketChannels = [];
        state.ticketChannelsCount = 0;
        state.ticketChannelsError = action.error?.payload?.error?.detail || 'Произошла ошибка';

        state.isLoadingTicketChannels = false;
      });
    builder
      .addCase(getTicketChannel.pending, (state) => {
        state.isLoadingTicketChannel = true;

        state.ticketChannelError = false;
      })
      .addCase(getTicketChannel.fulfilled, (state, action) => {
        state.ticketChannel = action.payload.ticketChannel;

        state.isLoadingTicketChannel = false;
      })
      .addCase(getTicketChannel.rejected, (state, action) => {
        state.isLoadingTicketChannel = false;

        state.ticketChannelError = action.payload?.detail || 'Произошла ошибка';
      });
  },
});

export const { resetTicketChannel } = ticketChannelsSlice.actions;

export default ticketChannelsSlice.reducer;
