// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Contracts
export const getContracts = createAsyncThunk('contracts/getContracts', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getContractsEndpoint, {
      params: {
        ...params,
      },
    });
    const data = response.data;

    return {
      contracts: data.results || [],
      count: data.count || 0,
      filters: params || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Contract
export const getContract = createAsyncThunk('contracts/getContract', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getContractEndpoint.replace(':id', params.id));
    const data = response.data;

    return {
      contract: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Contract
export const editContract = createAsyncThunk('contracts/editContract', async (params, { rejectWithValue }) => {
  const { id, ...other } = params;

  try {
    const response = await http.patch(apiConfig.editContractEndpoint.replace(':id', id), other);
    const data = response.data;

    return {
      contract: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState: {
    isLoadingContracts: false,
    isLoadingContract: false,
    contracts: [],
    contract: {},
    contractsCount: 0,
    contractsError: false,
    contractError: false,
    contractsFilters: {
      page: 1,
      page_size: 25,
    },
  },
  reducers: {
    resetContract: (state) => {
      state.isLoadingContract = false;
      state.contractError = false;
      state.contract = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContracts.pending, (state) => {
        state.isLoadingContracts = true;
        state.contractsError = false;
      })
      .addCase(getContracts.fulfilled, (state, action) => {
        state.contracts = action.payload.contracts;
        state.contractsCount = action.payload.count;
        state.contractsFilters = action.payload.filters;

        state.isLoadingContracts = false;
      })
      .addCase(getContracts.rejected, (state, action) => {
        state.isLoadingContracts = false;
        state.contracts = [];
        state.contractsCount = 0;

        state.contractsError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getContract.pending, (state) => {
        state.isLoadingContract = true;

        state.contractError = false;
      })
      .addCase(getContract.fulfilled, (state, action) => {
        state.contract = action.payload.contract;

        state.isLoadingContract = false;
      })
      .addCase(getContract.rejected, (state, action) => {
        state.isLoadingContract = false;

        state.contractError = action.payload?.detail || 'Произошла ошибка';
      });
    builder.addCase(editContract.fulfilled, (state, action) => {
      state.contract = action.payload.contract;
    });
  },
});

export const { resetContract } = contractsSlice.actions;

export default contractsSlice.reducer;
