// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Ticket Forms
export const getTicketForms = createAsyncThunk('ticketForms/getTicketForms', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getTicketFormsEndpoint, {
      params: params,
    });
    const data = response.data;

    return {
      ticketForms: data.results || [],
      count: data.count || 0,
      filters: params || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Create Ticket Form
export const createTicketForm = createAsyncThunk('ticketForms/createTicketForm', async (params, { rejectWithValue }) => {
  try {
    const response = await http.post(apiConfig.createTicketFormEndpoint, params);
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Ticket Form
export const editTicketForm = createAsyncThunk('ticketForms/editTicketForm', async (params, { rejectWithValue }) => {
  const { id, ...other } = params;

  try {
    const response = await http.patch(apiConfig.editTicketFormEndpoint.replace(':id', id), other);
    const data = response.data;

    return {
      ticketForm: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Delete Ticket Form
export const deleteTicketForm = createAsyncThunk(
  'ticketForms/deleteTicketForm',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.delete(apiConfig.deleteTicketFormEndpoint.replace(':id', params.id));
      const data = response.data;

      dispatch(
        getTicketForms({
          contract: params.contract,
        }),
      );

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Ticket Form
export const getTicketForm = createAsyncThunk('ticketForms/getTicketForm', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getTicketFormEndpoint.replace(':id', params.id));
    const data = response.data;

    return {
      ticketForm: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const ticketFormsSlice = createSlice({
  name: 'ticketForms',
  initialState: {
    isLoadingTicketForms: false,
    isLoadingTicketForm: false,
    ticketForms: [],
    ticketForm: {},
    ticketFormsCount: 0,
    ticketFormsError: false,
    ticketFormError: false,
    ticketFormsFilters: {
      page: 1,
      page_size: 25,
    },
  },
  reducers: {
    resetTicketForm: (state) => {
      state.isLoadingTicketForm = false;
      state.ticketFormError = false;
      state.ticketForm = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketForms.pending, (state) => {
        state.isLoadingTicketForms = true;
        state.ticketFormsError = false;
      })
      .addCase(getTicketForms.fulfilled, (state, action) => {
        state.ticketForms = action.payload.ticketForms;
        state.ticketFormsCount = action.payload.count;
        state.ticketFormsFilters = action.payload.filters;

        state.isLoadingTicketForms = false;
      })
      .addCase(getTicketForms.rejected, (state, action) => {
        state.ticketForms = [];
        state.ticketFormsCount = 0;
        state.ticketFormsError = action.error?.payload?.error?.detail || 'Произошла ошибка';

        state.isLoadingTicketForms = false;
      });
    builder
      .addCase(getTicketForm.pending, (state) => {
        state.isLoadingTicketForm = true;

        state.ticketFormError = false;
      })
      .addCase(getTicketForm.fulfilled, (state, action) => {
        state.ticketForm = action.payload.ticketForm;

        state.isLoadingTicketForm = false;
      })
      .addCase(getTicketForm.rejected, (state, action) => {
        state.isLoadingTicketForm = false;

        state.ticketFormError = action.payload?.detail || 'Произошла ошибка';
      });
  },
});

export const { resetTicketForm } = ticketFormsSlice.actions;

export default ticketFormsSlice.reducer;
