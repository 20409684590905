// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Utils
import localStorageService from 'src/@core/utils/local-storage';

// ** Get Tickets
export const getTickets = createAsyncThunk('tickets/getTickets', async (params, { rejectWithValue }) => {
  const { ...other } = params;

  for (const key of Object.keys(other)) {
    if (other[key] === '') {
      delete other[key];
    }
  }

  try {
    const response = await http.get(apiConfig.getTicketsEndpoint, {
      params: {
        ...other,
      },
    });
    const data = response.data;

    return {
      tickets: data.results || [],
      count: data.count || 0,
      filters: params || {},
    };
  } catch (error) {
    return rejectWithValue({
      error: error.response.data,
      filters: params || {},
    });
  }
});

// ** Create Ticket
export const createTicket = createAsyncThunk('tickets/createTicket', async (params, { rejectWithValue }) => {
  const { formData, channel, attachments, transport } = params;

  try {
    const fd = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    fd.append('channel', channel);
    fd.append('formData', JSON.stringify(formData));
    fd.append('transport', transport);

    for (let i = 0; i < attachments.length; i++) {
      fd.append('attachments', attachments[i]);
    }

    const response = await http.post(apiConfig.createTicketEndpoint, fd, config);
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Ticket
export const editTicket = createAsyncThunk('tickets/editTicket', async (params, { rejectWithValue }) => {
  try {
    const response = await http.patch(apiConfig.editTicketEndpoint.replace(':id', params.id), params);
    const data = response.data;

    return {
      ticket: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Delete Ticket
export const deleteTicket = createAsyncThunk('tickets/deleteTicket', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await http.delete(apiConfig.deleteTicketEndpoint.replace(':id', params.id));
    const data = response.data;

    dispatch(
      getTickets({
        channel__contract: params.contract,
      }),
    );

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Ticket
export const getTicket = createAsyncThunk('tickets/getTicket', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getTicketEndpoint.replace(':id', params.id));
    const data = response.data;

    return {
      ticket: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Ticket Activities
export const getTicketActivities = createAsyncThunk('tickets/getTicketActivities', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getTicketActivitiesEndpoint, {
      params: {
        page_size: 999,
        ticket: params.id,
        ordering: 'created',
        order: '-created',
      },
    });
    const data = response.data;

    return {
      ticketActivities: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Post Ticket Comment
export const postTicketComment = createAsyncThunk(
  'tickets/postTicketComment',
  async (params, { rejectWithValue, dispatch, getState }) => {
    const ticketId = getState().tickets.ticket.id;

    try {
      const response = await http.post(apiConfig.addTicketCommentEndpoint, {
        action: params.action,
        actionType: 'comment',
        ticket: params.id,
        ...(params.replyTo
          ? {
              replyTo: params.replyTo,
            }
          : {}),
      });
      const data = response.data;

      dispatch(getTicketActivities({ id: ticketId }));

      return {
        comment: data || {},
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    isLoadingTickets: false,
    isLoadingTicket: false,
    isLoadingTicketActivities: false,
    tickets: [],
    ticket: {},
    ticketsCount: 0,
    ticketsError: false,
    ticketError: false,
    ticketActivitiesError: false,
    ticketsFilters: {
      page: 1,
      page_size: localStorageService.get('ticketsFilters')?.page_size || 25,
      status: localStorageService.get('ticketsFilters')?.status || '',
      ordering: localStorageService.get('ticketsFilters')?.ordering || 'id',
    },
    ticketActivities: [],
  },
  reducers: {
    resetTicket: (state) => {
      state.isLoadingTicket = false;
      state.ticketError = false;
      state.ticket = {};
      state.ticketActivities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickets.pending, (state) => {
        state.isLoadingTickets = true;
        state.ticketsError = false;
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        state.tickets = action.payload.tickets;
        state.ticketsCount = action.payload.count;
        state.ticketsFilters = action.payload.filters;

        state.isLoadingTickets = false;

        localStorageService.set('ticketsFilters', action.payload.filters);
      })
      .addCase(getTickets.rejected, (state, action) => {
        state.tickets = [];
        state.ticketsCount = 0;
        state.ticketsFilters = action.payload.filters;
        state.ticketsError = action.error?.payload?.error?.detail || 'Произошла ошибка';

        state.isLoadingTickets = false;

        localStorageService.set('ticketsFilters', action.payload.filters);
      });
    builder
      .addCase(getTicket.pending, (state) => {
        state.isLoadingTicket = true;

        state.ticketError = false;
      })
      .addCase(getTicket.fulfilled, (state, action) => {
        state.ticket = action.payload.ticket;

        state.isLoadingTicket = false;
      })
      .addCase(getTicket.rejected, (state, action) => {
        state.isLoadingTicket = false;

        state.ticketError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getTicketActivities.pending, (state) => {
        state.isLoadingTicketActivities = true;

        state.ticketActivitiesError = false;
      })
      .addCase(getTicketActivities.fulfilled, (state, action) => {
        state.ticketActivities = action.payload.ticketActivities;

        state.isLoadingTicketActivities = false;
      })
      .addCase(getTicketActivities.rejected, (state, action) => {
        state.isLoadingTicketActivities = false;

        state.ticketActivitiesError = action.payload?.detail || 'Произошла ошибка';
      });
  },
});

export const { resetTicket } = ticketsSlice.actions;

export default ticketsSlice.reducer;
