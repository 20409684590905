// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Ticket Channel Sources
export const getTicketChannelSources = createAsyncThunk(
  'ticketChannelSources/getTicketChannelSources',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getTicketChannelSourcesEndpoint, {
        params: params,
      });
      const data = response.data;

      return {
        ticketChannelSources: data.results || [],
        count: data.count || 0,
        filters: params || {},
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Create Ticket Channel Source
export const createTicketChannelSource = createAsyncThunk(
  'ticketChannelSources/createTicketChannelSource',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.post(apiConfig.createTicketChannelSourceEndpoint, params);
      const data = response.data;

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Edit Ticket Channel Source
export const editTicketChannelSource = createAsyncThunk(
  'ticketChannelSources/editTicketChannelSource',
  async (params, { rejectWithValue }) => {
    const { id, ...other } = params;

    try {
      const response = await http.patch(apiConfig.editTicketChannelSourceEndpoint.replace(':id', id), other);
      const data = response.data;

      return {
        ticketChannelSource: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Delete Ticket Channel Source
export const deleteTicketChannelSource = createAsyncThunk(
  'ticketChannelSources/deleteTicketChannelSource',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.delete(apiConfig.deleteTicketChannelSourceEndpoint.replace(':id', params.id));
      const data = response.data;

      dispatch(
        getTicketChannelSources({
          contract: params.contract,
        }),
      );

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Ticket Channel Source
export const getTicketChannelSource = createAsyncThunk(
  'ticketChannelSources/getTicketChannelSource',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getTicketChannelSourceEndpoint.replace(':id', params.id));
      const data = response.data;

      return {
        ticketChannelSource: data || {},
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const ticketChannelSourcesSlice = createSlice({
  name: 'ticketChannelSources',
  initialState: {
    isLoadingTicketChannelSources: false,
    isLoadingTicketChannelSource: false,
    ticketChannelSources: [],
    ticketChannelSource: {},
    ticketChannelSourcesCount: 0,
    ticketChannelSourcesError: false,
    ticketChannelSourceError: false,
    ticketChannelSourcesFilters: {
      page: 1,
      page_size: 25,
    },
  },
  reducers: {
    resetTicketChannelSource: (state) => {
      state.isLoadingTicketChannelSource = false;
      state.ticketChannelSourceError = false;
      state.ticketChannelSource = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketChannelSources.pending, (state) => {
        state.isLoadingTicketChannelSources = true;
        state.ticketChannelSourcesError = false;
      })
      .addCase(getTicketChannelSources.fulfilled, (state, action) => {
        state.ticketChannelSources = action.payload.ticketChannelSources;
        state.ticketChannelSourcesCount = action.payload.count;
        state.ticketChannelSourcesFilters = action.payload.filters;

        state.isLoadingTicketChannelSources = false;
      })
      .addCase(getTicketChannelSources.rejected, (state, action) => {
        state.ticketChannelSources = [];
        state.ticketChannelSourcesCount = 0;
        state.ticketChannelSourcesError = action.error?.payload?.error?.detail || 'Произошла ошибка';

        state.isLoadingTicketChannelSources = false;
      });
    builder
      .addCase(getTicketChannelSource.pending, (state) => {
        state.isLoadingTicketChannelSource = true;

        state.ticketChannelSourceError = false;
      })
      .addCase(getTicketChannelSource.fulfilled, (state, action) => {
        state.ticketChannelSource = action.payload.ticketChannelSource;

        state.isLoadingTicketChannelSource = false;
      })
      .addCase(getTicketChannelSource.rejected, (state, action) => {
        state.isLoadingTicketChannelSource = false;

        state.ticketChannelSourceError = action.payload?.detail || 'Произошла ошибка';
      });
  },
});

export const { resetTicketChannelSource } = ticketChannelSourcesSlice.actions;

export default ticketChannelSourcesSlice.reducer;
